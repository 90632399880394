import * as React from 'react';
import {PositionComponent} from '../_PositionComponent';

export default () => <PositionComponent
    title={'Product Owner'}
    aboutPositionTitle={'The Project'}
    aboutPosition={`We’re building a part of a brand new product for our partner, a leading fintech organization.<br/><br/>
                    You will work closely with colleagues from our partner to translate their business needs into solutions
                    that will guide them to long-term value and success. By leveraging existing vendor API’s and
                    Microsoft Azure platform, the challenge will be to rapidly deliver and iterate usable technology solutions
                    that scale to 1M+ users with an eye towards an effective feedback loop from our partner.`}
    aboutRole={`This is a leadership role. In this capacity, you will drive the overall strategic direction for a suite of fintech products and systems, with direct responsibility for business requirements definition, technical specifications, and overall guidance of both engineering and non-technical teams.
    You will drive all aspects of the product development lifecycle, including feature design, architectural specification, and overseeing in tandem with our technical team lead the implementation efforts of our engineering team.
                The fintech product suite has a user facing experience that millions of consumers will utilize to improve their daily lives.`}
    aboutYou={  [
                    `Technical background as a programmer at some point in your career`,
                    `Extensive leadership experience of both technical and non-technical teams`,
                    `Stellar verbal and written English communication skills, close to that of a native speaker`,
                    `.NET / C# experience is a plus`,
                ]}
/>;
